import {
  Box,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Menu,
  Pagination,
  Button,
  Chip
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { Navigate, useNavigate } from "react-router-dom";
import { SlOptionsVertical } from "react-icons/sl";
import { AiOutlineEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  ieltsCenterManagmentCenterDeleteAction,
  ieltsCenterManagmentCenterStatusChangeAction
} from "../../../actions/IeltsActions/centerManagmentAction";
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from "sweetalert2";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";
import PremissionDialog from "../../customComponent/premissionDialog";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import {
  leadApprovalRequestApproveAction,
  leadApprovalRequestRejectAction,
  leadDuplicateApproveAction,
  leadDuplicateRejectAction,
  leadOwnerChangeApprovalRequestApproveAction,
  leadOwnerChangeApprovalRequestRejectAction,
  leadPassportApprovalRequestApproveAction,
  leadPassportEditApproveRequestAction
} from "../../../actions/leadActions/leadActions";
import Dialogue from "../../customComponent/Dialogue";

function LeadApprovalTable({
  data,
  columns,
  component,
  selectBoxIdPassHandler,
  pageNumber,
  subMenu
}) {
  useEffect(() => {
    if (pageNumber > 10) {
      setRowsPerPage(pageNumber);
    } else {
      setRowsPerPage(10);
    }
  }, [pageNumber]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isStatusChangePrevilage,
    isUpdatePrevilage,
    isDeletePrevilage,
    isCreatePrevilage,
    isApprovePrevilage
  } = usePrivilegeCheck();

  const [status, setStatus] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [reason, setReason] = useState(false);
  const [dialogComponent, setDialogComponent] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  // Sort icon

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="customIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="customIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    setPage(1);
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  // Handle status change
  const handleStatusChange = (status, id) => {
    if (isStatusChangePrevilage(subMenu)) {
      dispatch(ieltsCenterManagmentCenterStatusChangeAction(status, id));
    } else {
      setOpenAlert(true);
    }
    // console.log(status, id);
    // setStatus(status);
  };

  const handleSelectAllRows = () => {
    if (selectedRows?.length === sortedData?.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.sl);
      setSelectedRows(Ids);
      selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    //  setIdsFunction(updatedSelectedRows);
  };

  //  Table actions

  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [leadId, setLeadId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  console.log(sortedData, "sortedDatasortedDatasortedData");

  return sortedData?.length === 0 ? (
    <TableNoItemComponent />
  ) : (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography
          sx={{
            backgroundColor: "#fff",
            padding: "5px",
            paddingRight: "0px",
            borderRadius: "10px 10px 0 0",
            width: "100%",
            fontSize: "16px",
            fontWeight: "400"
          }}
        >
          Showing{" "}
          <Typography
            component={"span"}
            sx={{
              backgroundColor: "#fff",
              padding: "5px",
              paddingLeft: "0px",
              borderRadius: "10px 10px 0 0",
              width: "100%",
              fontSize: "16px",
              // lineHeight: "24px",
              fontWeight: "700"
            }}
          >
            {
              sortedData?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )?.length
            }{" "}
            Result
          </Typography>
        </Typography>

        <div
          style={{
            overflowX: "scroll",
            border: "0px solid #ffff"
          }}
          className="scrollBar"
        >
          <Table className="LeadTable">
            <TableHead
              sx={{
                background: "#F9F9FB"
              }}
              className="LeadTableCell LeadCommon"
            >
              <TableRow className="LeadTableCell">
                <TableCell
                  style={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1,
                    padding: "1px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fafafa"
                  }}
                >
                  <Checkbox
                    checked={selectedRows?.length === sortedData?.length}
                    onChange={handleSelectAllRows}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                {columns?.map((column) => {
                  if (column.hidden) {
                    return null;
                  }
                  return (
                    <TableCell
                      key={column.field}
                      className="LeadTableCell LeadCommon"
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      <TableSortLabel
                        className="LeadHeaderText"
                        active={column === sortBy}
                        direction={sortOrder}
                        onClick={() => requestSort(column)}
                        IconComponent={
                          column.field !== "action" && CustomSortIcon
                        }
                      >
                        {column.title}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                ?.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item, indx) => (
                  <TableRow className="LeadTable" key={indx}>
                    <TableCell
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1, // To ensure it's above other cells
                        backgroundColor: "white", // Adjust as needed
                        padding: "1px",
                        border: "1px solid #ddd"
                      }}
                    >
                      <Checkbox
                        checked={selectedRows.includes(item.sl)}
                        onChange={() => handleSelectRow(item.sl)}
                        style={{ color: "#f81b82" }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {indx + 1}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {item?.name}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {item?.email}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {item?.phone}
                    </TableCell>
                    {component == "leadOwnerChangeApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.currentOwner}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {component == "leadOwnerChangeApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.requestedOwner}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {component == "leadOwnerChangeApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.currentBranch}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {component == "leadOwnerChangeApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.requestedBranch}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {component == "leadOwnerChangeApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.rejectReason ? item?.rejectReason : "-"}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {component == "leadOwnerChangeApproval" ||
                    component == "DuplicateLeadApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.updatedBy}
                      </TableCell>
                    ) : (
                      ""
                    )}

                    {component == "DuplicateLeadApproval" && (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.reason}
                      </TableCell>
                    )}
                    {component == "leadEditApproval" ||
                    component == "leadPassportApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.whatsappNumber}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {component == "leadEditApproval" ||
                    component == "leadPassportApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.leadSource}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {component == "leadEditApproval" ||
                    component == "leadPassportApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.leadOwner}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {component == "leadPassportApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.updatedPassportNumber}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {component == "leadEditApproval" && (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.counsilor}
                      </TableCell>
                    )}
                    {component == "leadEditApproval" && (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.updatedEmail !== "-" && (
                          <span>{item.updatedEmail}</span>
                        )}
                        {item?.updatedPhone !== "-" && (
                          <span>{item.updatedPhone}</span>
                        )}
                        {item?.updateSource !== "-" && (
                          <span>{item.updateSource}</span>
                        )}
                      </TableCell>
                    )}
                    {component == "leadEditApproval" ||
                    component == "leadPassportApproval" ? (
                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {item?.updatedBy}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {item.status == "Waiting for Approval" ||
                      item.status == "Waiting for Approval" ? (
                        <>
                          <Button
                            sx={{
                              backgroundColor: "rgba(34, 130, 0, 1)",
                              color: "white",
                              width: "76px",
                              height: "25px",
                              fontWeight: 400,
                              fontSize: "12px",
                              mr: 2,
                              "&:hover": {
                                backgroundColor: "rgba(34, 110, 0, 0.5)"
                              }
                            }}
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "do You want to approve this ?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, approve it!"
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  if (isApprovePrevilage(subMenu)) {
                                    component == "leadOwnerChangeApproval"
                                      ? dispatch(
                                          leadOwnerChangeApprovalRequestApproveAction(
                                            item.leadId,
                                            item.id
                                          )
                                        )
                                      : component == "DuplicateLeadApproval"
                                      ? dispatch(
                                          leadDuplicateApproveAction(item.id)
                                        )
                                      : component == "leadPassportApproval" &&
                                        subMenu !== "leadPassportEditApproval"
                                      ? dispatch(
                                          leadPassportApprovalRequestApproveAction(
                                            item.leadId,
                                            item.id
                                          )
                                        )
                                      : subMenu == "leadPassportEditApproval"
                                      ? dispatch(
                                          leadPassportEditApproveRequestAction(
                                            item.id,
                                            item.leadId
                                          )
                                        )
                                      : dispatch(
                                          leadApprovalRequestApproveAction(
                                            item.id
                                          )
                                        );
                                  } else {
                                    setOpenAlert(true);
                                  }
                                }
                              });
                            }}
                          >
                            Approved
                          </Button>
                          <Button
                            sx={{
                              backgroundColor: "rgba(229, 41, 0, 1)",
                              color: "white",
                              width: "76px",
                              height: "25px",
                              fontWeight: 400,
                              fontSize: "12px",
                              ml: 1,
                              "&:hover": {
                                backgroundColor: "rgba(229, 21, 30, 0.5)"
                              }
                            }}
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "Do You want to Reject this ?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, Reject it!"
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  if (isApprovePrevilage(subMenu)) {
                                    if (
                                      component === "leadOwnerChangeApproval"
                                    ) {
                                      setDialogComponent(
                                        "leadOwnerChangeApproval"
                                      );
                                      setReason(true);
                                      setTableId(item.id);
                                      setLeadId(item.leadId);
                                    } else if (
                                      component === "DuplicateLeadApproval"
                                    ) {
                                      setReason(true);
                                      setDialogComponent(
                                        "LeadDuplicateRejectReason"
                                      );
                                      setTableId(item.id);
                                    } else if (
                                      component === "leadPassportApproval" &&
                                      subMenu !== "leadPassportEditApproval"
                                    ) {
                                      setReason(true);
                                      setDialogComponent(
                                        "LeadPassportApproval"
                                      );
                                      setTableId(item.id);
                                      setLeadId(item.leadId);
                                    } else if (
                                      subMenu == "leadPassportEditApproval"
                                    ) {
                                      setReason(true);
                                      setDialogComponent(
                                        "leadPassportEditApproval"
                                      );
                                      setTableId(item.id);
                                      setLeadId(item.leadId);
                                    } else if (
                                      component === "leadEditApproval"
                                    ) {
                                      setReason(true);
                                      setDialogComponent("leadEditApproval");
                                      setTableId(item.id);
                                      // setLeadId(item.leadId);
                                    } else {
                                      dispatch(
                                        leadApprovalRequestRejectAction(item.id)
                                      );
                                    }
                                  } else {
                                    setOpenAlert(true);
                                  }
                                }
                              });
                            }}
                          >
                            Reject
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                      {item.status == "Approved" ||
                      item.status == "Rejected" ? (
                        <Chip
                          key={item.id}
                          sx={{
                            height: "auto",
                            borderRadius: "3px",
                            backgroundColor:
                              item.status === "Approved"
                                ? "rgba(34, 130, 1, 0.2)"
                                : "rgba(229, 21, 30, 0.2)",
                            color:
                              item.status === "Approved"
                                ? "rgba(34, 130, 0, 1)"
                                : "rgba(229, 41, 0, 1)",
                            "& .MuiChip-label": {
                              display: "block",
                              whiteSpace: "normal",
                              fontSize: "14px",
                              fontWeight: 400
                            }
                          }}
                          label={
                            item.status === "Approved" ? "Approved" : "Rejected"
                          }
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>

                    {component !== "DuplicateLeadApproval" &&
                    component !== "leadPassportApproval" &&
                    component !== "leadOwnerChangeApproval" &&
                    component !== "leadEditApproval" ? (
                      <TableCell
                        style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                      >
                        <SlOptionsVertical
                          id="demo-positioned-button"
                          aria-controls={
                            leadOpenActionButton
                              ? "demo-positioned-menu"
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            leadOpenActionButton ? "true" : undefined
                          }
                          onClick={(e) => {
                            handleClickAction(e, item.id);
                          }}
                          variant="outlined"
                          style={{ marginLeft: "10px", color: "#F80B7A" }}
                          sx={{
                            backgroundColor: "#141E3C",
                            size: "10px",
                            padding: 1,
                            margin: 3,
                            color: "#fff",
                            width: "75px",
                            height: "40px"
                          }}
                        />
                        {/* TABLE ACTION MENU START*/}
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          open={leadActionButtonShow}
                          onClose={handleCloseAction}
                          anchorEl={leadActionButtonShow}
                          MenuListProps={{
                            "aria-labelledby": "basic-button"
                          }}
                          anchorOrigin={{
                            vertical: "bottom", // Change from "top" to "bottom"
                            horizontal: "left"
                          }}
                          transformOrigin={{
                            vertical: "top", // Change from "top" to "bottom"
                            horizontal: "left"
                          }}
                          sx={{
                            borderColor: "black",
                            borderRadius: "24px",
                            mt: 1.5,
                            width: "auto",
                            height: "auto"
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end"
                            }}
                          >
                            <MenuItem>
                              <AiOutlineEye
                                style={{
                                  fontSize: "lg",
                                  width: "20px",
                                  height: "19px"
                                }}
                                onClick={() => {
                                  navigate(
                                    `/ielts/center/detailedview/${tableId}`
                                  );
                                }}
                              />
                            </MenuItem>
                          </Box>
                        </Menu>{" "}
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter />
          </Table>
        </div>

        <Pagination
          sx={{
            mt: "28px",
            "& .Mui-selected": {
              backgroundColor: "#f81b82 !important",
              color: "#fff"
            },
            "& .MuiPagination-ul": {
              justifyContent: "end"
            }
          }}
          count={Math.ceil(data?.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}

      {reason === true && (
        <Dialogue
          openTrue={reason}
          handleClose={() => setReason(false)}
          headerName="Reason"
          Dailogcomponent={dialogComponent}
          appliedApplicationId={tableId}
          modalData={leadId}
        />
      )}
    </>
  );
}

export default LeadApprovalTable;
