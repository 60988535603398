export const LEAD_CREATE_SUBMIT_REQUEST = "LEAD_CREATE_SUBMIT_REQUEST";
export const LEAD_CREATE_SUBMIT_SUCCESS = "LEAD_CREATE_SUBMIT_SUCCESS";
export const LEAD_CREATE_SUBMIT_ERR = "LEAD_CREATE_SUBMIT_ERR";

export const LEAD_EDIT_SUBMIT_REQUEST = "LEAD_EDIT_SUBMIT_REQUEST";
export const LEAD_EDIT_SUBMIT_SUCCESS = "LEAD_EDIT_SUBMIT_SUCCESS";
export const LEAD_EDIT_SUBMIT_ERR = "LEAD_EDIT_SUBMIT_ERR";

export const LEAD_FIND_REQUEST = "LEAD_FIND_REQUEST";
export const LEAD_FIND_SUCCESS = "LEAD_FIND_SUCCESS";
export const LEAD_FIND_ERR = "LEAD_FIND_ERR";

export const LEAD_DELETE_REQUEST = "LEAD_DELETE_REQUEST";
export const LEAD_DELETE_SUCCESS = "LEAD_DELETE_SUCCESS";
export const LEAD_DELETE_ERR = "LEAD_DELETE_ERR";

export const A_LEAD_DETAIL_FETCH_REQUEST = "A_LEAD_DETAIL_FETCH_REQUEST";
export const A_LEAD_DETAIL_FETCH_SUCCESS = "A_LEAD_DETAIL_FETCH_SUCCESS";
export const A_LEAD_DETAIL_FETCH_ERR = "A_LEAD_DETAIL_FETCH_ERR";

// personal data
export const LEAD_PERSONAL_DETAILS_ADD_REQUEST =
  "LEAD_PERSONAL_DETAILS_ADD_REQUEST";
export const LEAD_PERSONAL_DETAILS_ADD_SUCCESS =
  "LEAD_PERSONAL_DETAILS_ADD_SUCCESS";
  export const LEAD_PERSONAL_DETAILS_ADD_SUCCESS_MESSAGE =
  "LEAD_PERSONAL_DETAILS_ADD_SUCCESS_MESSAGE";
export const LEAD_PERSONAL_DETAILS_ADD_ERR = "LEAD_PERSONAL_DETAILS_ADD_ERR";
export const LEAD_PERSONAL_DETAILS_ADD_ERR_MESSAGE = "LEAD_PERSONAL_DETAILS_ADD_ERR_MESSAGE";


export const LEAD_PERSONAL_DETAILS_SHOW_REQUEST =
  "LEAD_PERSONAL_DETAILS_SHOW_REQUEST";
export const LEAD_PERSONAL_DETAILS_SHOW_SUCCESS =
  "LEAD_PERSONAL_DETAILS_SHOW_SUCCESS";
export const LEAD_PERSONAL_DETAILS_SHOW_ERR = "LEAD_PERSONAL_DETAILS_SHOW_ERR";

export const LEAD_STATUS_SHOW_REQUEST = "LEAD_STATUS_SHOW_REQUEST";
export const LEAD_STATUS_SHOW_SUCCESS = "LEAD_STATUS_SHOW_SUCCESS";
export const LEAD_STATUS_SHOW_ERR = "LEAD_STATUS_SHOW_ERR";

// education data
export const LEAD_EDUCATION_DETAILS_ADD_REQUEST =
  "LEAD_EDUCATION_DETAILS_ADD_REQUEST";
export const LEAD_EDUCATION_DETAILS_ADD_SUCCESS =
  "LEAD_EDUCATION_DETAILS_ADD_SUCCESS";
export const LEAD_EDUCATION_DETAILS_ADD_ERR = "LEAD_EDUCATION_DETAILS_ADD_ERR";

export const LEAD_EDUCATION_DETAILS_FIND_REQUEST =
  "LEAD_EDUCATION_DETAILS_FIND_REQUEST";
export const LEAD_EDUCATION_DETAILS_FIND_SUCCESS =
  "LEAD_EDUCATION_DETAILS_FIND_SUCCESS";
export const LEAD_EDUCATION_DETAILS_FIND_ERR =
  "LEAD_EDUCATION_DETAILS_FIND_ERR";

export const LEAD_EDUCATION_DETAILS_DELETE_REQUEST =
  "LEAD_EDUCATION_DETAILS_DELETE_REQUEST";
export const LEAD_EDUCATION_DETAILS_DELETE_SUCCESS =
  "LEAD_EDUCATION_DETAILS_DELETE_SUCCESS";
export const LEAD_EDUCATION_DETAILS_DELETE_ERR =
  "LEAD_EDUCATION_DETAILS_DELETE_ERR";

export const LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_REQUEST =
  "LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_REQUEST";
export const LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_SUCCESS =
  "LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_SUCCESS";
export const LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_ERR =
  "LEAD_EDUCATION_ENGLISH_TEST_DETAILS_DELETE_ERR";

export const LEAD_OWNER_CHANGE_REQUEST = "LEAD_OWNER_CHANGE_REQUEST";
export const LEAD_OWNER_CHANGE_SUCCESS = "LEAD_OWNER_CHANGE_SUCCESS";
export const LEAD_OWNER_CHANGE_ERR = "LEAD_OWNER_CHANGE_ERR";

// lead sop
export const LEAD_SOP_PDF_UPLOAD_REQUEST = "LEAD_SOP_PDF_UPLOAD_REQUEST";
export const LEAD_SOP_PDF_UPLOAD_SUCCESS = "LEAD_SOP_PDF_UPLOAD_SUCCESS";
export const LEAD_SOP_PDF_UPLOAD_ERR = "LEAD_SOP_PDF_UPLOAD_ERR";

export const LEAD_SOP_PDF_FINDING_REQUEST = "LEAD_SOP_PDF_FINDING_REQUEST";
export const LEAD_SOP_PDF_FINDING_SUCCESS = "LEAD_SOP_PDF_FINDING_SUCCESS";
export const LEAD_SOP_PDF_FINDING_ERR = "LEAD_SOP_PDF_FINDING_ERR";

// Lead criminal

export const LEAD_CRIMINAL_PDF_UPLOAD_REQUEST = "LEAD_CRIMINAL_PDF_UPLOAD_REQUEST";
export const LEAD_CRIMINAL_PDF_UPLOAD_SUCCESS = "LEAD_CRIMINAL_PDF_UPLOAD_SUCCESS";
export const LEAD_CRIMINAL_PDF_UPLOAD_ERR = "LEAD_CRIMINAL_PDF_UPLOAD_ERR";

export const LEAD_CRIMINAL_PDF_FINDING_REQUEST = "LEAD_CRIMINAL_PDF_FINDING_REQUEST";
export const LEAD_CRIMINAL_PDF_FINDING_SUCCESS = "LEAD_CRIMINAL_PDF_FINDING_SUCCESS";
export const LEAD_CRIMINAL_PDF_FINDING_ERR = "LEAD_CRIMINAL_PDF_FINDING_ERR";


// Lead medical


export const LEAD_MEDICAL_PDF_UPLOAD_REQUEST = "LEAD_MEDICAL_PDF_UPLOAD_REQUEST";
export const LEAD_MEDICAL_PDF_UPLOAD_SUCCESS = "LEAD_MEDICAL_PDF_UPLOAD_SUCCESS";
export const LEAD_MEDICAL_PDF_UPLOAD_ERR = "LEAD_MEDICAL_PDF_UPLOAD_ERR";

export const LEAD_MEDICAL_PDF_FINDING_REQUEST = "LEAD_MEDICAL_PDF_FINDING_REQUEST";
export const LEAD_MEDICAL_PDF_FINDING_SUCCESS = "LEAD_MEDICAL_PDF_FINDING_SUCCESS";
export const LEAD_MEDICAL_PDF_FINDING_ERR = "LEAD_MEDICAL_PDF_FINDING_ERR";


export const LEAD_SOP_PDF_STATUS_CHANGE_REQUEST =
  "LEAD_SOP_PDF_STATUS_CHANGE_REQUEST";
export const LEAD_SOP_PDF_STATUS_CHANGE_SUCCESS =
  "LEAD_SOP_PDF_STATUS_CHANGE_SUCCESS";
export const LEAD_SOP_PDF_STATUS_CHANGE_ERR = "LEAD_SOP_PDF_STATUS_CHANGE_ERR";

// lead travel and immigration
export const LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_REQUEST =
  "LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_REQUEST";
export const LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_SUCCESS =
  "LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_SUCCESS";
export const LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_ERR =
  "LEAD_TRAVELIMMIGRATION_PDF_UPLOAD_ERR";

export const LEAD_TRAVELIMMIGRATION_PDF_FINDING_REQUEST =
  "LEAD_TRAVELIMMIGRATION_PDF_FINDING_REQUEST";
export const LEAD_TRAVELIMMIGRATION_PDF_FINDING_SUCCESS =
  "LEAD_TRAVELIMMIGRATION_PDF_FINDING_SUCCESS";
export const LEAD_TRAVELIMMIGRATION_PDF_FINDING_ERR =
  "LEAD_TRAVELIMMIGRATION_PDF_FINDING_ERR";

// lead work details
export const LEAD_WORK_DETAILS_ADD_REQUEST = "LEAD_WORK_DETAILS_ADD_REQUEST";
export const LEAD_WORK_DETAILS_ADD_SUCCESS = "LEAD_WORK_DETAILS_ADD_SUCCESS";
export const LEAD_WORK_DETAILS_ADD_ERR = "LEAD_WORK_DETAILS_ADD_ERR";

export const LEAD_WORK_DETAILS_FIND_REQUEST = "LEAD_WORK_DETAILS_FIND_REQUEST";
export const LEAD_WORK_DETAILS_FIND_SUCCESS = "LEAD_WORK_DETAILS_FIND_SUCCESS";
export const LEAD_WORK_DETAILS_FIND_ERR = "LEAD_WORK_DETAILS_FIND_ERR";

export const LEAD_WORK_DETAILS_DELETE_REQUEST =
  "LEAD_WORK_DETAILS_DELETE_REQUEST";
export const LEAD_WORK_DETAILS_DELETE_SUCCESS =
  "LEAD_WORK_DETAILS_DELETE_SUCCESS";
export const LEAD_WORK_DETAILS_DELETE_ERR = "LEAD_WORK_DETAILS_DELETE_ERR";

// Admision Docs
export const COUNTRY_ADMISSION_DOCS_FIND_REQUEST =
  "COUNTRY_ADMISSION_DOCS_FIND_REQUEST";
export const COUNTRY_ADMISSION_DOCS_FIND_SUCCESS =
  "COUNTRY_ADMISSION_DOCS_FIND_SUCCESS";
export const COUNTRY_ADMISSION_DOCS_FIND_ERR =
  "COUNTRY_ADMISSION_DOCS_FIND_ERR";

// leadCountrycommonDocUploading
export const LEAD_COUNTRY_COMMON_DOCS_UPLOADING_REQUEST =
  "LEAD_COUNTRY_COMMON_DOCS_UPLOADING_REQUEST";
export const LEAD_COUNTRY_COMMON_DOCS_UPLOADING_SUCCESS =
  "LEAD_COUNTRY_COMMON_DOCS_UPLOADING_SUCCESS";
export const LEAD_COUNTRY_COMMON_DOCS_UPLOADING_ERR =
  "LEAD_COUNTRY_COMMON_DOCS_UPLOADING_ERR";

export const LEAD_COUNTRY_COMMON_DOCS_FINDING_REQUEST =
  "LEAD_COUNTRY_COMMON_DOCS_FINDING_REQUEST";
export const LEAD_COUNTRY_COMMON_DOCS_FINDING_SUCCESS =
  "LEAD_COUNTRY_COMMON_DOCS_FINDING_SUCCESS";
export const LEAD_COUNTRY_COMMON_DOCS_FINDING_ERR =
  "LEAD_COUNTRY_COMMON_DOCS_FINDING_ERR";

// leadCountrySpecficDocUploading
export const LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_REQUEST =
  "LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_REQUEST";
export const LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_SUCCESS =
  "LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_SUCCESS";
export const LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_ERR =
  "LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_ERR";


// lead Other Document uploading constants

export const LEAD_OTHER_DOCUMENT_UPLOADING_REQUEST =
  "LEAD_OTHER_DOCUMENT_UPLOADING_REQUEST";
export const LEAD_OTHER_DOCUMENT_UPLOADING_SUCCESS =
  "LEAD_OTHER_DOCUMENT_UPLOADING_SUCCESS";
export const LEAD_OTHER_DOCUMENT_UPLOADING_ERR =
  "LEAD_OTHER_DOCUMENT_UPLOADING_ERR";


// lead Other Document Find constants

export const LEAD_OTHER_DOCUMENT_FIND_REQUEST =
  "LEAD_OTHER_DOCUMENT_FIND_REQUEST";
export const LEAD_OTHER_DOCUMENT_FIND_SUCCESS =
  "LEAD_OTHER_DOCUMENT_FIND_SUCCESS";
export const LEAD_OTHER_DOCUMENT_FIND_ERR =
  "LEAD_OTHER_DOCUMENT_FIND_ERR";


// lead Other Document delete constants

export const LEAD_OTHER_DOCUMENT_DELETE_REQUEST =
  "LEAD_OTHER_DOCUMENT_DELETE_REQUEST";
export const LEAD_OTHER_DOCUMENT_DELETE_SUCCESS =
  "LEAD_OTHER_DOCUMENT_DELETE_SUCCESS";
export const LEAD_OTHER_DOCUMENT_DELETE_ERR =
  "LEAD_OTHER_DOCUMENT_DELETE_ERR";


// leadDocuments reUpload constants

export const LEAD_DOCUMENT_REUPLOADING_REQUEST =
  "LEAD_DOCUMENT_REUPLOADING_REQUEST";
export const LEAD_DOCUMENT_REUPLOADING_SUCCESS =
  "LEAD_DOCUMENT_REUPLOADING_SUCCESS";
export const LEAD_DOCUMENT_REUPLOADING_ERR = "LEAD_DOCUMENT_REUPLOADING_ERR";

export const LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_REQUEST =
  "LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_REQUEST";
export const LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_SUCCESS =
  "LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_SUCCESS";
export const LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_ERR =
  "LEAD_COUNTRY_SPECIFIC_DOCS_FINDING_ERR";

// short list constant
export const SHORT_LIST_UNIVERSITY_COURSE_FINDING_REQUEST =
  "SHORT_LIST_UNIVERSITY_COURSE_FINDING_REQUEST";
export const SHORT_LIST_UNIVERSITY_COURSE_FINDING_SUCCESS =
  "SHORT_LIST_UNIVERSITY_COURSE_FINDING_SUCCESS";
export const SHORT_LIST_UNIVERSITY_COURSE_FINDING_MESSAGE =
  "SHORT_LIST_UNIVERSITY_COURSE_FINDING_MESSAGE";
export const SHORT_LIST_UNIVERSITY_COURSE_FINDING_ERR =
  "SHORT_LIST_UNIVERSITY_COURSE_FINDING_ERR";

export const ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_REQUEST =
  "ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_REQUEST";
export const ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS =
  "ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS";
export const ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_ERR =
  "ADD_TO_SHORT_LIST_UNIVERSITY_COURSE_ERR";

export const FIND_SHORT_LIST_UNIVERSITY_COURSE_REQUEST =
  "FIND_SHORT_LIST_UNIVERSITY_COURSE_REQUEST";
export const FIND_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS =
  "FIND_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS";
export const FIND_SHORT_LIST_UNIVERSITY_COURSE_ERR =
  "FIND_SHORT_LIST_UNIVERSITY_COURSE_ERR";

export const SHORT_LIST_METHOD_APPLY_REQUEST =
  "SHORT_LIST_METHOD_APPLY_REQUEST";
export const SHORT_LIST_METHOD_APPLY_SUCCESS =
  "SHORT_LIST_METHOD_APPLY_SUCCESS";
export const SHORT_LIST_METHOD_APPLY_ERR = "SHORT_LIST_METHOD_APPLY_ERR";

export const SHORT_LIST_METHOD_DELETE_REQUEST =
  "SHORT_LIST_METHOD_DELETE_REQUEST";
export const SHORT_LIST_METHOD_DELETE_SUCCESS =
  "SHORT_LIST_METHOD_DELETE_SUCCESS";
export const SHORT_LIST_METHOD_DELETE_ERR = "SHORT_LIST_METHOD_DELETE_ERR";

export const SHORT_LIST_METHOD_FIND_REQUEST = "SHORT_LIST_METHOD_FIND_REQUEST";
export const SHORT_LIST_METHOD_FIND_SUCCESS = "SHORT_LIST_METHOD_FIND_SUCCESS";
export const SHORT_LIST_METHOD_FIND_ERR = "SHORT_LIST_METHOD_FIND_ERR";

export const PAYMENT_STATUS_UPDATE_SHORTLIST_REQUEST =
  "PAYMENT_STATUS_UPDATE_SHORTLIST_REQUEST";
export const PAYMENT_STATUS_UPDATE_SHORTLIST_SUCCESS =
  "PAYMENT_STATUS_UPDATE_SHORTLIST_SUCCESS";
export const PAYMENT_STATUS_UPDATE_SHORTLIST_ERR =
  "PAYMENT_STATUS_UPDATE_SHORTLIST_ERR";

export const APPLICATION_VERIFIED_LEAD_FIND_REQUEST =
  "APPLICATION_VERIFIED_LEAD_FIND_REQUEST";
export const APPLICATION_VERIFIED_LEAD_FIND_SUCCESS =
  "APPLICATION_VERIFIED_LEAD_FIND_SUCCESS";
export const APPLICATION_VERIFIED_LEAD_FIND_ERR =
  "APPLICATION_VERIFIED_LEAD_FIND_ERR";

export const LEAD_TIME_LINE_FIND_REQUEST = "LEAD_TIME_LINE_FIND_REQUEST";
export const LEAD_TIME_LINE_FIND_SUCCESS = "LEAD_TIME_LINE_FIND_SUCCESS";
export const LEAD_TIME_LINE_FIND_ERR = "LEAD_TIME_LINE_FIND_ERR";

export const LEAD_TIME_LINE_STATUS_ADD_REQUEST =
  "LEAD_TIME_LINE_STATUS_ADD_REQUEST";
export const LEAD_TIME_LINE_STATUS_ADD_SUCCESS =
  "LEAD_TIME_LINE_STATUS_ADD_SUCCESS";
export const LEAD_TIME_LINE_STATUS_ADD_ERR = "LEAD_TIME_LINE_STATUS_ADD_ERR";

// LEAD PENDING DOCS STATUS

export const LEAD_PENDING_STATUS_CHANGE_REQUEST =
  "LEAD_PENDING_STATUS_CHANGE_REQUEST";
export const LEAD_PENDING_STATUS_CHANGE_SUCCESS =
  "LEAD_PENDING_STATUS_CHANGE_SUCCESS";
export const LEAD_PENDING_STATUS_CHANGE_ERR = "LEAD_PENDING_STATUS_CHANGE_ERR";

export const FIND_LEAD_PENDING_STATUS_CHANGE_REQUEST =
  "FIND_LEAD_PENDING_STATUS_CHANGE_REQUEST";
export const FIND_LEAD_PENDING_STATUS_CHANGE_SUCCESS =
  "FIND_LEAD_PENDING_STATUS_CHANGE_SUCCESS";
export const FIND_LEAD_PENDING_STATUS_CHANGE_ERR =
  "FIND_LEAD_PENDING_STATUS_CHANGE_ERR";

// FOLLOW UP CONSTANT

export const FOLLOW_UP_TASK_ADD_REQUEST = "FOLLOW_UP_TASK_ADD_REQUEST";
export const FOLLOW_UP_TASK_ADD_SUCCESS = "FOLLOW_UP_TASK_ADD_SUCCESS";
export const FOLLOW_UP_TASK_ADD_ERR = "FOLLOW_UP_TASK_ADD_ERR";

export const FOLLOW_UP_TASK_EDIT_REQUEST = "FOLLOW_UP_TASK_EDIT_REQUEST";
export const FOLLOW_UP_TASK_EDIT_SUCCESS = "FOLLOW_UP_TASK_EDIT_SUCCESS";
export const FOLLOW_UP_TASK_EDIT_ERR = "FOLLOW_UP_TASK_EDIT_ERR";

export const FOLLOW_UP_TASK_FIND_REQUEST = "FOLLOW_UP_TASK_FIND_REQUEST";
export const FOLLOW_UP_TASK_FIND_SUCCESS = "FOLLOW_UP_TASK_FIND_SUCCESS";
export const FOLLOW_UP_TASK_FIND_ERR = "FOLLOW_UP_TASK_FIND_ERR";

export const FOLLOW_UP_STATUS_CHANGE_REQUEST =
  "FOLLOW_UP_STATUS_CHANGE_REQUEST";
export const FOLLOW_UP_STATUS_CHANGE_SUCCESS =
  "FOLLOW_UP_STATUS_CHANGE_SUCCESS";
export const FOLLOW_UP_STATUS_CHANGE_ERR = "FOLLOW_UP_STATUS_CHANGE_ERR";

export const FOLLOW_UP_SOCIAL_MEDIA_ADD_REQUEST =
  "FOLLOW_UP_SOCIAL_MEDIA_ADD_REQUEST";
export const FOLLOW_UP_SOCIAL_MEDIA_ADD_SUCCESS =
  "FOLLOW_UP_SOCIAL_MEDIA_ADD_SUCCESS";
export const FOLLOW_UP_SOCIAL_MEDIA_ADD_ERR = "FOLLOW_UP_SOCIAL_MEDIA_ADD_ERR";

export const FOLLOW_UP_SOCIAL_MEDIA_FIND_REQUEST =
  "FOLLOW_UP_SOCIAL_MEDIA_FIND_REQUEST";
export const FOLLOW_UP_SOCIAL_MEDIA_FIND_SUCCESS =
  "FOLLOW_UP_SOCIAL_MEDIA_FIND_SUCCESS";
export const FOLLOW_UP_SOCIAL_MEDIA_FIND_ERR =
  "FOLLOW_UP_SOCIAL_MEDIA_FIND_ERR";

export const FOLLOW_UP_NOTE_FIND_REQUEST = "FOLLOW_UP_NOTE_FIND_REQUEST";
export const FOLLOW_UP_NOTE_FIND_SUCCESS = "FOLLOW_UP_NOTE_FIND_SUCCESS";
export const FOLLOW_UP_NOTE_FIND_ERR = "FOLLOW_UP_NOTE_FIND_ERR";

export const FOLLOW_UP_ATTACHMENT_FIND_REQUEST =
  "FOLLOW_UP_ATTACHMENT_FIND_REQUEST";
export const FOLLOW_UP_ATTACHMENT_FIND_SUCCESS =
  "FOLLOW_UP_ATTACHMENT_FIND_SUCCESS";
export const FOLLOW_UP_ATTACHMENT_FIND_ERR = "FOLLOW_UP_ATTACHMENT_FIND_ERR";

export const FOLLOW_UP_COMPOSE_MAIL_REQUEST = "FOLLOW_UP_COMPOSE_MAIL_REQUEST";
export const FOLLOW_UP_COMPOSE_MAIL_SUCCESS = "FOLLOW_UP_COMPOSE_MAIL_SUCCESS";
export const FOLLOW_UP_COMPOSE_MAIL_ERR = "FOLLOW_UP_COMPOSE_MAIL_ERR";

export const FOLLOW_UP_COMPOSE_MAIL_FIND_REQUEST =
  "FOLLOW_UP_COMPOSE_MAIL_FIND_REQUEST";
export const FOLLOW_UP_COMPOSE_MAIL_FIND_SUCCESS =
  "FOLLOW_UP_COMPOSE_MAIL_FIND_SUCCESS";
export const FOLLOW_UP_COMPOSE_MAIL_FIND_ERR =
  "FOLLOW_UP_COMPOSE_MAIL_FIND_ERR";

export const FOLLOW_UP_ADD_LINK_REQUEST = "FOLLOW_UP_ADD_LINK_REQUEST";
export const FOLLOW_UP_ADD_LINK_SUCCESS = "FOLLOW_UP_ADD_LINK_SUCCESS";
export const FOLLOW_UP_ADD_LINK_ERR = "FOLLOW_UP_ADD_LINK_ERR";

export const FOLLOW_UP_FIND_LINK_REQUEST = "FOLLOW_UP_FIND_LINK_REQUEST";
export const FOLLOW_UP_FIND_LINK_SUCCESS = "FOLLOW_UP_FIND_LINK_SUCCESS";
export const FOLLOW_UP_FIND_LINK_ERR = "FOLLOW_UP_FIND_LINK_ERR";

// FIND APPROVING LEAD STATUS

export const FIND_APPROVING_LEAD_STATUS_REQUEST =
  "FIND_APPROVING_LEAD_STATUS_REQUEST";
export const FIND_APPROVING_LEAD_STATUS_SUCCESS =
  "FIND_APPROVING_LEAD_STATUS_SUCCESS";
export const FIND_APPROVING_LEAD_STATUS_ERR = "FIND_APPROVING_LEAD_STATUS_ERR";

export const CHANGE_APPROVING_LEAD_STATUS_REQUEST =
  "CHANGE_APPROVING_LEAD_STATUS_REQUEST";
export const CHANGE_APPROVING_LEAD_STATUS_SUCCESS =
  "CHANGE_APPROVING_LEAD_STATUS_SUCCESS";
export const CHANGE_APPROVING_LEAD_STATUS_ERR =
  "CHANGE_APPROVING_LEAD_STATUS_ERR";

// LEAD RESTART APPLICATION FROM DEFERRAL

export const LEAD_RESTART_APPLICATION_REQUEST =
  "LEAD_RESTART_APPLICATION_REQUEST";
export const LEAD_RESTART_APPLICATION_SUCCESS =
  "LEAD_RESTART_APPLICATION_SUCCESS";
export const LEAD_RESTART_APPLICATION_ERR = "LEAD_RESTART_APPLICATION_ERR";

// COUNSELOR CHAT WITH LEADS

// check student available for students

export const FIND_CHAT_WITH_COUNSELOR_REQUEST =
  "FIND_CHAT_WITH_COUNSELOR_REQUEST";
export const FIND_CHAT_WITH_COUNSELOR_SUCCESS =
  "FIND_CHAT_WITH_COUNSELOR_SUCCESS";
export const FIND_CHAT_WITH_COUNSELOR_ERR = "FIND_CHAT_WITH_COUNSELOR_ERR ";

// Lead bulk upload constant

export const LEAD_BULKUPLOAD_REQUEST = "LEAD_BULKUPLOAD_REQUEST";
export const LEAD_BULKUPLOAD_SUCCESS = "LEAD_BULKUPLOAD_SUCCESS";
export const LEAD_BULKUPLOAD_ERR = "LEAD_BULKUPLOAD_ERR ";

// Lead registrationFee create constants

export const LEAD_REGISTRATIONFEE_CREATE_REQUEST =
  "LEAD_REGISTRATIONFEE_CREATE_REQUEST";
export const LEAD_REGISTRATIONFEE_CREATE_SUCCESS =
  "LEAD_REGISTRATIONFEE_CREATE_SUCCESS";
export const LEAD_REGISTRATIONFEE_CREATE_ERR =
  "LEAD_REGISTRATIONFEE_CREATE_ERR ";

// Lead registrationFee find constants

export const LEAD_REGISTRATIONFEE_FIND_REQUEST =
  "LEAD_REGISTRATIONFEE_FIND_REQUEST";
export const LEAD_REGISTRATIONFEE_FIND_SUCCESS =
  "LEAD_REGISTRATIONFEE_FIND_SUCCESS";
export const LEAD_REGISTRATIONFEE_FIND_ERR = "LEAD_REGISTRATIONFEE_CREATE_ERR ";

// Lead registrationFee find constants

export const SHORTLIST_VALIDATION_FIND_REQUEST =
  "SHORTLIST_VALIDATION_FIND_REQUEST";
export const SHORTLIST_VALIDATION_FIND_SUCCESS =
  "SHORTLIST_VALIDATION_FIND_SUCCESS";
export const SHORTLIST_VALIDATION_FIND_ERR = "SHORTLIST_VALIDATION_FIND_ERR ";

// :::::::::::::::::::: LEAD EDIT APPROVAL::::::::::::::::::::::::::::::::::::::::::;:

// Lead approval request find

export const LEAD_APPROVAL_REQUEST_FIND_REQUEST =
  "LEAD_APPROVAL_REQUEST_FIND_REQUEST";
export const LEAD_APPROVAL_REQUEST_FIND_SUCCESS =
  "LEAD_APPROVAL_REQUEST_FIND_SUCCESS";
export const LEAD_APPROVAL_REQUEST_FIND_ERR = "LEAD_APPROVAL_REQUEST_FIND_ERR ";

// Lead approval request approve

export const LEAD_APPROVAL_REQUEST_APPROVE_REQUEST =
  "LEAD_APPROVAL_REQUEST_APPROVE_REQUEST";
export const LEAD_APPROVAL_REQUEST_APPROVE_SUCCESS =
  "LEAD_APPROVAL_REQUEST_APPROVE_SUCCESS";
export const LEAD_APPROVAL_REQUEST_APPROVE_ERR =
  "LEAD_APPROVAL_REQUEST_APPROVE_ERR ";

// Lead approval request approve

export const LEAD_APPROVAL_REQUEST_REJECT_REQUEST =
  "LEAD_APPROVAL_REQUEST_REJECT_REQUEST";
export const LEAD_APPROVAL_REQUEST_REJECT_SUCCESS =
  "LEAD_APPROVAL_REQUEST_REJECT_SUCCESS";
export const LEAD_APPROVAL_REQUEST_REJECT_ERR =
  "LEAD_APPROVAL_REQUEST_REJECT_ERR ";

// :::::::::::::::::::: LEAD OWNERCHANGE APPROVAL::::::::::::::::::::::::::::::::::::::::::;:

// Lead ownerChange approval find request

export const LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_REQUEST =
  "LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_REQUEST";
export const LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_SUCCESS =
  "LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_SUCCESS";
export const LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_ERR =
  "LEAD_OWNERCHANGE_APPROVAL_REQUEST_FIND_ERR ";

// Lead ownerChange approval approve request

export const LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_REQUEST =
  "LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_REQUEST";
export const LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_SUCCESS =
  "LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_SUCCESS";
export const LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_ERR =
  "LEAD_OWNERCHANGE_APPROVAL_REQUEST_APPROVE_ERR ";

// Lead ownerChange approval reject request

export const LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_REQUEST =
  "LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_REQUEST";
export const LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_SUCCESS =
  "LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_SUCCESS";
export const LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_ERR =
  "LEAD_OWNERCHANGE_APPROVAL_REQUEST_REJECT_ERR ";

//  ::::::::::::::::::::::: Lead Credential Change constants :::::::::::::::::::::

export const LEAD_CREDENTIAL_RESEND_REQUEST = "LEAD_CREDENTIAL_RESEND_REQUEST";
export const LEAD_CREDENTIAL_RESEND_SUCCESS = "LEAD_CREDENTIAL_RESEND_SUCCESS";
export const LEAD_CREDENTIAL_RESEND_ERR = "LEAD_CREDENTIAL_RESEND_ERR ";

export const LEAD_CREDENTIAL_SETPASSWORD_REQUEST =
  "LEAD_CREDENTIAL_SETPASSWORD_REQUEST";
export const LEAD_CREDENTIAL_SETPASSWORD_SUCCESS =
  "LEAD_CREDENTIAL_SETPASSWORD_SUCCESS";
export const LEAD_CREDENTIAL_SETPASSWORD_ERR =
  "LEAD_CREDENTIAL_SETPASSWORD_ERR ";

// ::::::::::::::::::::: Lead Counsilor note constants :::::::::::::::::::::;

export const LEAD_COUNSILORNOTE_CREATE_REQUEST = "LEAD_COUNSILORNOTE_CREATE_REQUEST";
export const LEAD_COUNSILORNOTE_CREATE_SUCCESS = "LEAD_COUNSILORNOTE_CREATE_SUCCESS";
export const LEAD_COUNSILORNOTE_CREATE_ERR = "LEAD_COUNSILORNOTE_CREATE_ERR ";

// ::::::::::::::::::::: Lead Telecaller note constants :::::::::::::::::::::;

export const LEAD_TELECALLERNOTE_CREATE_REQUEST = "LEAD_TELECALLERNOTE_CREATE_REQUEST";
export const LEAD_TELECALLERNOTE_CREATE_SUCCESS = "LEAD_TELECALLERNOTE_CREATE_SUCCESS";
export const LEAD_TELECALLERNOTE_CREATE_ERR = "LEAD_TELECALLERNOTE_CREATE_ERR ";


// ::::::::::::::::::::: Lead Marketing note constants :::::::::::::::::::::;

export const LEAD_MARKETINGNOTE_CREATE_REQUEST = "LEAD_MARKETINGNOTE_CREATE_REQUEST";
export const LEAD_MARKETINGNOTE_CREATE_SUCCESS = "LEAD_MARKETINGNOTE_CREATE_SUCCESS";
export const LEAD_MARKETINGNOTE_CREATE_ERR = "LEAD_MARKETINGNOTE_CREATE_ERR ";

// :::::::::::::::: Lead duplicate Approval find :::::::::::::::::::::::::::::

export const LEAD_DUPLICATE_APPROVAL_FIND_REQUEST = "LEAD_DUPLICATE_APPROVAL_FIND_REQUEST";
export const LEAD_DUPLICATE_APPROVAL_FIND_SUCCESS = "LEAD_DUPLICATE_APPROVAL_FIND_SUCCESS";
export const LEAD_DUPLICATE_APPROVAL_FIND_ERR = "LEAD_DUPLICATE_APPROVAL_FIND_ERR ";


// :::::::::::::::: Lead duplicate Approval findOne :::::::::::::::::::::::::::::

export const LEAD_DUPLICATE_APPROVAL_FINDONE_REQUEST = "LEAD_DUPLICATE_APPROVAL_FINDONE_REQUEST";
export const LEAD_DUPLICATE_APPROVAL_FINDONE_SUCCESS = "LEAD_DUPLICATE_APPROVAL_FINDONE_SUCCESS";
export const LEAD_DUPLICATE_APPROVAL_FINDONE_ERR = "LEAD_DUPLICATE_APPROVAL_FINDONE_ERR ";


// :::::::::::::::: Lead duplicate Approval Approve :::::::::::::::::::::::::::::

export const LEAD_DUPLICATE_APPROVAL_APPROVE_REQUEST = "LEAD_DUPLICATE_APPROVAL_APPROVE_REQUEST";
export const LEAD_DUPLICATE_APPROVAL_APPROVE_SUCCESS = "LEAD_DUPLICATE_APPROVAL_APPROVE_SUCCESS";
export const LEAD_DUPLICATE_APPROVAL_APPROVE_ERR = "LEAD_DUPLICATE_APPROVAL_APPROVE_ERR ";

// :::::::::::::::: Lead duplicate Approval Reject :::::::::::::::::::::::::::::

export const LEAD_DUPLICATE_APPROVAL_REJECT_REQUEST = "LEAD_DUPLICATE_APPROVAL_REJECT_REQUEST";
export const LEAD_DUPLICATE_APPROVAL_REJECT_SUCCESS = "LEAD_DUPLICATE_APPROVAL_REJECT_SUCCESS";
export const LEAD_DUPLICATE_APPROVAL_REJECT_ERR = "LEAD_DUPLICATE_APPROVAL_REJECT_ERR ";


// :::::::::::::::: Lead refund create :::::::::::::::::::::::::::::

export const LEAD_REFUND_CREATE_REQUEST = "LEAD_REFUND_CREATE_REQUEST";
export const LEAD_REFUND_CREATE_SUCCESS = "LEAD_REFUND_CREATE_SUCCESS";
export const LEAD_REFUND_CREATE_ERR = "LEAD_REFUND_CREATE_ERR ";


// :::::::::::::::: Lead refund find :::::::::::::::::::::::::::::

export const LEAD_REFUND_FIND_REQUEST = "LEAD_REFUND_FIND_REQUEST";
export const LEAD_REFUND_FIND_SUCCESS = "LEAD_REFUND_FIND_SUCCESS";
export const LEAD_REFUND_FIND_ERR = "LEAD_REFUND_FIND_ERR ";



// :::::::::::::::: Lead refund approval :::::::::::::::::::::::::::::

export const LEAD_REFUND_APPROVAL_REQUEST = "LEAD_REFUND_APPROVAL_REQUEST";
export const LEAD_REFUND_APPROVAL_SUCCESS = "LEAD_REFUND_APPROVAL_SUCCESS";
export const LEAD_REFUND_APPROVAL_ERR = "LEAD_REFUND_APPROVAL_ERR ";


// :::::::::::::::: Lead refund update :::::::::::::::::::::::::::::

export const LEAD_REFUND_UPDATE_REQUEST = "LEAD_REFUND_UPDATE_REQUEST";
export const LEAD_REFUND_UPDATE_SUCCESS = "LEAD_REFUND_UPDATE_SUCCESS";
export const LEAD_REFUND_UPDATE_ERR = "LEAD_REFUND_UPDATE_ERR ";


// :::::::::::::::: Lead phoneNumber and  email verification constants ::::::::::::::::::

export const LEAD_PHONENUMBER_VERIFIED_REQUEST = "LEAD_PHONENUMBER_VERIFIED_REQUEST";
export const LEAD_PHONENUMBER_VERIFIED_SUCCESS = "LEAD_PHONENUMBER_VERIFIED_SUCCESS";
export const LEAD_PHONENUMBER_VERIFIED_ERR = "LEAD_PHONENUMBER_VERIFIED_ERR ";



export const LEAD_EMAIL_VERIFIED_REQUEST = "LEAD_EMAIL_VERIFIED_REQUEST";
export const LEAD_EMAIL_VERIFIED_SUCCESS = "LEAD_EMAIL_VERIFIED_SUCCESS";
export const LEAD_EMAIL_VERIFIED_ERR = "LEAD_EMAIL_VERIFIED_ERR ";


export const LEAD_OTP_VERIFY_REQUEST = "LEAD_OTP_VERIFY_REQUEST";
export const LEAD_OTP_VERIFY_SUCCESS = "LEAD_OTP_VERIFY_SUCCESS";
export const LEAD_OTP_VERIFY_ERR = "LEAD_OTP_VERIFY_ERR ";


//  :::::::::::::::::::::::::::::::: lead Document delete Constants ::::::::::::::::::::

export const LEAD_DOCUMENT_DELETE_REQUEST = "LEAD_DOCUMENT_DELETE_REQUEST";
export const LEAD_DOCUMENT_DELETE_SUCCESS = "LEAD_DOCUMENT_DELETE_SUCCESS";
export const LEAD_DOCUMENT_DELETE_ERR = "LEAD_DOCUMENT_DELETE_ERR ";



// :::::::::::::::::::: LEAD PASSPORT VERIFY APPROVAL::::::::::::::::::::::::::::::::::::::::::;:

// Lead approval request find

export const LEAD_PASSPORT_APPROVAL_FIND_REQUEST =
  "LEAD_PASSPORT_APPROVAL_FIND_REQUEST";
export const LEAD_PASSPORT_APPROVAL_FIND_SUCCESS =
  "LEAD_PASSPORT_APPROVAL_FIND_SUCCESS";
export const LEAD_PASSPORT_APPROVAL_FIND_ERR = "LEAD_PASSPORT_APPROVAL_FIND_ERR ";


// Lead approval request approve

export const LEAD_PASSPORT_APPROVAL_APPROVE_REQUEST =
  "LEAD_PASSPORT_APPROVAL_APPROVE_REQUEST";
export const LEAD_PASSPORT_APPROVAL_APPROVE_SUCCESS =
  "LEAD_PASSPORT_APPROVAL_APPROVE_SUCCESS";
export const LEAD_PASSPORT_APPROVAL_APPROVE_ERR = "LEAD_PASSPORT_APPROVAL_APPROVE_ERR ";


// Lead approval request approve

export const LEAD_PASSPORT_APPROVAL_REJECT_REQUEST =
  "LEAD_PASSPORT_APPROVAL_REJECT_REQUEST";
export const LEAD_PASSPORT_APPROVAL_REJECT_SUCCESS =
  "LEAD_PASSPORT_APPROVAL_REJECT_SUCCESS";
export const LEAD_PASSPORT_APPROVAL_REJECT_ERR = "LEAD_PASSPORT_APPROVAL_REJECT_ERR ";


// Lead passport edit request

export const LEAD_PASSPORT_EDIT_REQUEST =
  "LEAD_PASSPORT_EDIT_REQUEST";
export const LEAD_PASSPORT_EDIT_SUCCESS =
  "LEAD_PASSPORT_EDIT_SUCCESS";
export const LEAD_PASSPORT_EDIT_ERR = "LEAD_PASSPORT_EDIT_ERR ";


// Lead passport edit request find

export const LEAD_PASSPORT_EDIT_FIND_REQUEST =
  "LEAD_PASSPORT_EDIT_FIND_REQUEST";
export const LEAD_PASSPORT_EDIT_FIND_SUCCESS =
  "LEAD_PASSPORT_EDIT_FIND_SUCCESS";
export const LEAD_PASSPORT_EDIT_FIND_ERR = "LEAD_PASSPORT_EDIT_FIND_ERR ";



// Lead passport edit request approve

export const LEAD_PASSPORT_EDIT_APPROVE_REQUEST =
  "LEAD_PASSPORT_EDIT_APPROVE_REQUEST";
export const LEAD_PASSPORT_EDIT_APPROVE_SUCCESS =
  "LEAD_PASSPORT_EDIT_APPROVE_SUCCESS";
export const LEAD_PASSPORT_EDIT_APPROVE_ERR = "LEAD_PASSPORT_EDIT_APPROVE_ERR ";


// Lead passport edit request reject

export const LEAD_PASSPORT_EDIT_REJECT_REQUEST =
  "LEAD_PASSPORT_EDIT_REJECT_REQUEST";
export const LEAD_PASSPORT_EDIT_REJECT_SUCCESS =
  "LEAD_PASSPORT_EDIT_REJECT_SUCCESS";
export const LEAD_PASSPORT_EDIT_REJECT_ERR = "LEAD_PASSPORT_EDIT_REJECT_ERR ";


// User Branch find

export const USER_BRANCH_FIND_REQUEST =
  "USER_BRANCH_FIND_REQUEST";
export const USER_BRANCH_FIND_SUCCESS =
  "USER_BRANCH_FIND_SUCCESS";
export const USER_BRANCH_FIND_ERR = "USER_BRANCH_FIND_ERR ";