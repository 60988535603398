import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import Select from "react-select";

// form
import {
  Grid,
  IconButton,
  Snackbar,
  Alert,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Autocomplete,
  TextField
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { leadAddAction,UserBranchFindAction } from "../../../actions/leadActions/leadActions";
import { findcountryMasterAddAction } from "../../../actions/countryMasterAction";

import "./LeadCreateForm.css";
import { SubmitButton } from "../../customComponent/Buttons";
import InputField, { SelectInputField } from "../../customComponent/InputField";

import Swal from "sweetalert2";
import { countries } from "countries-list";
import { Country, State } from "country-state-city";
import { City } from "country-state-city";
import { leadSourceFindAction } from "../../../actions/leadSourceAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant";

const genders = ["Male", "Female", "Other"];
// const leadSources = [
//   "Email Enquiry",
//   "Events",
//   "Facebook",
//   "Facebook Ads",
//   "Google Ads",
//   "IELTS Center",
//   "Influencer",
//   "Paid Referral",
//   "Unpaid Referral",
//   "Phone Enquiry",
//   "Walkin",
// ];

const allCountries = Object.entries(countries).map(([code, country]) => ({
  value: code,
  label: country.name,
  phone: `+${country.phone}`
}));
let isUserExist


function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function LeadCreatForm({ addForm, handleStateChange }) {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [open, setOpen] = React.useState(addForm);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedBranch, setSelectedBranch] = useState(null);


  const [isMarried, setIsMarried] = React.useState("");

  // form details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [secMobileNumber, setSecMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [allcountryCode, setAllCountryCode] = useState("");

  const [countryCodeSecondary, setCountryCodeSecondary] = useState("+91");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [location, setLocation] = useState("");
  const [state, setState] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [education, setEducation] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [leadSources, setLeadSources] = useState([]);
  const [referralName, setReferralName] = useState("");
  const [preferredCountry, setPreferredCountry] = useState([]);
  const [remark, setRemark] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [allCountry, setAllCountry] = useState([]);
  const [stateCode, setStateCode] = useState([]);
  const [allState, setAllStates] = useState([]);
  const [allCities, setAllCity] = useState([]);
  const [leadSourceType, setLeadSourceType] = useState({});

  const [passwordError, setPasswordError] = useState(false);
  const [leadADDError, setLeadAddError] = useState(false);
  const [error, setError] = useState({});

  let { leadAdderror, leadAddSuccess } = useSelector((state) => {
    return state.leadAdd;
  });

  let { countryFindSuccess } = useSelector((state) => {
    return state.findcountryMasterAdd;
  });

  let { findLeadSourceSuccess } = useSelector((state) => {
    return state.leadSourceFind;
  });

  let { userBranchFindRequestSuccess } = useSelector((state) => {
    return state.UserBranchFind;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  //  Find state if Country is Available

  useEffect(() => {
    if (selectedCountry) {
      const allStates = allCountries.filter(
        (value) => value.label == selectedCountry
      );
      console.log(allStates, "filterCountry");
      const countryCode = allStates[0]?.value;
      const states = State.getStatesOfCountry(countryCode);
      const stateLabels = states.map((state) => ({
        label: state.name
      }));
      const stateCode = states.map((state) => ({
        statecode: `${state.name}#${state.isoCode}`
      }));
      setAllCountryCode(countryCode);
      setStateCode(stateCode);
      setAllStates(stateLabels);
    }
  }, [selectedCountry]);

  // Find City if State is available

  useEffect(() => {
    if (selectedState) {
      const city = stateCode?.filter(
        (city) => selectedState == city.statecode.split("#")[0]
      );
      const Statecode = city[0]?.statecode.split("#")[1];
      console.log(Statecode, "stateCode");
      const stateCities = City.getCitiesOfState(allcountryCode, Statecode);
      setAllCity(stateCities);
      console.log(stateCities, "stateCities");
    }
  }, [selectedState, stateCode]);

  //  useEffect(() => {
  //    if (selectedCountry) {
  //      const allStates = allCountries.filter(
  //        (value) => value.label == selectedCountry.value
  //      );
  //      console.log(allCountries, "filterCountry");
  //      console.log(allStates, "filterCountry");
  //      const countryCode = allStates[0]?.value;
  //      const states = State.getStatesOfCountry(countryCode);
  //      const stateLabels = states.map((state) => ({
  //        label: state.name,
  //      }));
  //      const stateCode = states.map((state) => ({
  //        statecode: `${state.name}#${state.isoCode}`,
  //      }));
  //      //  setCountryCode(countryCode);
  //      //  setStateCode(stateCode);
  //      //  setAllStates(stateLabels);
  //    }
  //  }, [selectedCountry]);

  useEffect(() => {
     isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
    : null;
    dispatch(leadSourceFindAction(1));
    dispatch(UserBranchFindAction(isUserExist?.UserId))
  }, []);

  useEffect(() => {
    if (findLeadSourceSuccess) {
      setLeadSources(findLeadSourceSuccess);
    }
  }, [findLeadSourceSuccess]);
  console.log(
    findLeadSourceSuccess,
    "findLeadSourceSuccessfindLeadSourceSuccessfindLeadSourceSuccessfindLeadSourceSuccess"
  );

  useEffect(() => {
    if (countryFindSuccess) {
      setAllCountry(countryFindSuccess);
    }
  }, [countryFindSuccess]);

  useEffect(() => {
    if (leadSources?.length !== 0 && leadSource != "") {
      // console.log(
      //   leadSources[
      //     leadSources?.findIndex((item) => item?.leadSource == leadSource)
      //   ],

      //   "**********************************************************"
      // );

      setLeadSourceType(
        leadSources[
          leadSources?.findIndex((item) => item?.leadSource == leadSource)
        ]
      );
    }
  }, [leadSources, leadSource]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction());
  }, [dispatch]);

  useEffect(() => {
    if (leadAdderror && successAlertMsgErr) {
      setLeadAddError(true);
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [leadAdderror, successAlertMsgErr]);

  // country Code
  const handleCountryCodeChange = (event) => {
    let value = event.target.value;
    if (value) {
      setCountryCode(value);
    }
  };

  const handleCountryCodeSecondaryChange = (event) => {
    let value = event.target.value;
    if (value) {
      setCountryCodeSecondary(value);
    }
  };

  const handlePhoneChange = (event) => {
    const phoneNumber = event.target.value;
    if (/^\d{0,13}$/.test(phoneNumber) || /^\d{0,10}$/.test(phoneNumber)) {
      setMobileNumber(phoneNumber);
      setError((prev) => ({
        ...prev,
        ["mobileNumber"]: ""
      }));
    }
  };

  const handleSecondaryPhoneChange = (event) => {
    const phoneNumber = event.target.value;
    if (/^\d{0,13}$/.test(phoneNumber) || /^\d{0,10}$/.test(phoneNumber)) {
      setSecMobileNumber(phoneNumber);
    }
  };

  // PROFILE PIC
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);

      // Create a FileReader instance
      const reader = new FileReader();

      // Read the image file as a data URL
      reader.readAsDataURL(file);

      // Set the previewImage state when the reader has finished loading the image
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
    }
  };

  const handleIconButtonClick = () => {
    fileInputRef.current.click();
  };

  // country of living
  const handleCountryChange = (event,newValue) => {
    console.log(newValue, "selecteddddddddddddddddd");
    setSelectedCountry(newValue);
    setError((prev) => ({
      ...prev,
      ["selectedCountry"]: ""
    }));
  };

  const validate = () => {
    let errors = {};

    if (firstName === "") {
      errors.firstName = "FirstName is required";
    }
    // if (previewImage === "") {
    //   errors.previewImage = "Profile image is required";
    // }
    // if (lastName === "") {
    //   errors.lastName = "LastName is required";
    // }
    if (mobileNumber === "") {
      errors.mobileNumber = "MobileNumber is required";
    } else if (!/^\d{8,12}$/.test(mobileNumber)) {
      errors.phone = "Phone number must be 8 - 12 digits";
    }
    if (email === "") {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = "Invalid email address";
    }
    // if (gender === "") {
    //   errors.gender = "Gender is required";
    // }

    // if (selectedCountry === "") {
    //   errors.selectedCountry = "Country is required";
    // }
    // if (selectedState === "") {
    //   errors.selectedState = "State is required";
    // }
    // if (selectedCity === "") {
    //   errors.selectedCity = "City is required";
    // }

    if (whatsappNumber !== "") {
      if (!/^\d{8,12}$/.test(whatsappNumber)) {
        errors.phone = "Phone number must be 8 - 12 digits";
      }
    }

    if (location === "") {
      errors.location = "Location is required";
    }
    if (isUserExist?.name !== "admin") {
      if(selectedBranch == null){
        errors.selectedBranch = "Branch is required";
      }
    }

    if (leadSource === "") {
      errors.leadSource = "leadSource is required";
    }

    if (leadSourceType?.isMandatory == true && referralName == "") {
      errors.leadSourcetype = "This field is required";
    }

    // if (preferredCountry.length == 0) {
    //   errors.preferredCountry = "preferredCountry is required";
    // }

    // if (newPass == "") {
    //   errors.newPass = "Password is required";
    // }

    // if (confirmPass == "") {
    //   errors.confirmPass = "Confirm password is required";
    // }

    setError(errors);
    console.log(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form validation
    if (validate()) {
      // if (newPass === confirmPass) {
      const delimiter = ", ";
      const nameString = preferredCountry
        .map((obj) => obj.name)
        .join(delimiter);

      dispatch(
        leadAddAction(
          firstName,
          lastName,
          email,
          gender,
          mobileNumber,
          countryCode,
          whatsappNumber,
          location,
          dateOfBirth,
          education,
          leadSource,
          referralName,
          nameString,
          remark,
          previewImage,
          // newPass,
          // confirmPass,
          countryCodeSecondary,
          secMobileNumber,
          selectedCountry,
          isMarried,
          selectedState,
          selectedCity,
          selectedBranch
        )
      );

      setFirstName("");
      setLastName("");
      setNewPass("");
      setConfirmPass("");
      setEmail("");
      setGender("");
      setMobileNumber("");
      setWhatsappNumber("");
      setLocation("");
      setDateOfBirth("");
      setEducation("");
      setLeadSource("");
      setReferralName("");
      setPreferredCountry([]);
      setRemark("");
      setIsSubmitted(true);
      setPasswordError(false);

      handleClose();
      dispatch(successTrueMsgAction(true));

      // Swal.fire({
      //   icon: "success",
      //   title: "Created",
      //   text: "New Lead created!",
      // });
      // } else {
      //   setPasswordError(true);
      //   Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: "password not matched!",
      //   });
      // }
    }
  };

  console.log(userBranchFindRequestSuccess, "userBranchFindRequestSuccessuserBranchFindRequestSuccess");
  console.log(selectedBranch, "selectedBranchselectedBranchselectedBranch");


  const handleClose = () => {
    setOpen(false);
    handleStateChange(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        // aria-labelledby="draggable-dialog-title"
        PaperProps={{
          style: {
            minHeight: "100vh",
            maxHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            minWidth: "820px"
          }
        }}
      >
        <DialogTitle
          style={{ fontSize: "20px", fontWeight: 700 }}
          // id="draggable-dialog-title"
        >
          Add New Lead
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} justifyContent="center">
                {/* User Profile */}
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                  open={leadADDError}
                  message={leadAdderror}
                >
                  <Alert severity="error" sx={{ width: "100%" }}>
                    {leadAdderror}
                  </Alert>
                </Snackbar>

                <Grid item xs={12} textAlign="center">
                  <IconButton
                    sx={{
                      width: "150px",
                      height: "150px",
                      backgroundColor: "#fdfdfd"
                    }}
                    onClick={handleIconButtonClick}
                  >
                    {selectedFile ? (
                      <img
                        src={previewImage}
                        alt="Uploaded"
                        style={{
                          width: "140px",
                          height: "140px",
                          border: "1px solid",
                          borderRadius: "50%"
                        }}
                      />
                    ) : (
                      <img src="/menuIcons/user.png" alt="gsl_profile" />
                    )}
                  </IconButton>

                  <input
                    id="profileUpload"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                  />
                  <Typography
                    sx={{ fontWeight: 400, fontSize: "16px", mt: 0.5 }}
                  >
                    Profile Picture{" "}
                    {/* <span
                      className="text-danger"
                      style={{ color: "rgba(255, 45, 46, 1)" }}
                    >
                      *
                    </span> */}
                  </Typography>
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {error?.previewImage}
                  </span> */}
                </Grid>

                {/* First Name and Last Name */}
                <Grid item xs={6}>
                  <InputField
                    label="First Name"
                    handleChange={(event) => {
                      setFirstName(event.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["firstName"]: ""
                      }));
                    }}
                    value={firstName}
                    widthSize="99%"
                    requiredSymbol
                    error={Boolean(error?.firstName)}
                    helperText={error?.firstName}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    label="Last Name"
                    handleChange={(event) => {
                      setLastName(event.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["lastName"]: ""
                      }));
                    }}
                    value={lastName}
                    widthSize="99%"
                    // requiredSymbol
                    // error={Boolean(error?.lastName)}
                    // helperText={error?.lastName}
                  />
                </Grid>

                {/* New password and Confirm Password */}
                {/* <Grid item xs={6}>
                  <InputField
                    label="New Password"
                    handleChange={(event) => {
                      setNewPass(event.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["newPass"]: "",
                      }));
                    }}
                    value={newPass}
                    widthSize="99%"
                    InputType="password"
                    requiredSymbol
                    error={Boolean(error?.newPass)}
                    helperText={error?.newPass}
                  />
                </Grid> */}

                {/* <Grid item xs={6}>
                  <InputField
                    label="Confirm Password"
                    handleChange={(event) => {
                      setConfirmPass(event.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["confirmPass"]: "",
                      }));
                    }}
                    value={confirmPass}
                    widthSize="99%"
                    requiredSymbol
                    InputType="password"
                    error={Boolean(error?.confirmPass)}
                    helperText={error?.confirmPass}
                  />
                </Grid> */}

                {/* Email and Gender */}
                <Grid item xs={6}>
                  <InputField
                    label="Email"
                    handleChange={(event) => {
                      setEmail(event.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["Email"]: ""
                      }));
                    }}
                    value={email}
                    widthSize="99%"
                    requiredSymbol
                    InputType="email"
                    error={Boolean(error?.email)}
                    helperText={error?.email}
                  />
                </Grid>

                <Grid item xs={6}>
                  <SelectInputField
                    label="Gender"
                    handleSelectChange={(event) => {
                      setGender(event.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["gender"]: ""
                      }));
                    }}
                    widthSize="54px"
                    value={gender}
                    mapValue={genders}
                    mapMethod="gender"
                    // requiredSymbol
                  />
                  <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px"
                    }}
                  >
                    {error?.gender}
                  </span>
                </Grid>

                {/* Mobile Number */}
                <Grid item xs={2}>
                  <SelectInputField
                    handleSelectChange={handleCountryCodeChange}
                    value={countryCode}
                    mapValue={allCountries}
                    mapMethod="countryCode"
                  />
                </Grid>

                <Grid item xs={10}>
                  <InputField
                    label="Mobile Number"
                    handleChange={handlePhoneChange}
                    value={mobileNumber}
                    widthSize="99%"
                    requiredSymbol
                    InputType="number"
                    error={Boolean(error?.mobileNumber)}
                    helperText={error?.mobileNumber}
                  />
                </Grid>

                {/*secondary Mobile Number */}
                <Grid item xs={2}>
                  <SelectInputField
                    handleSelectChange={handleCountryCodeSecondaryChange}
                    value={countryCodeSecondary}
                    mapValue={allCountries}
                    mapMethod="countryCode"
                  />
                </Grid>

                <Grid item xs={10}>
                  <InputField
                    label="Secondary Mobile Number"
                    handleChange={handleSecondaryPhoneChange}
                    value={secMobileNumber}
                    widthSize="99%"
                    // requiredSymbol
                    InputType="number"
                  />
                </Grid>

                {/* WhatsApp Number */}
                <Grid item xs={12}>
                  <InputField
                    label="WhatsApp Number"
                    handleChange={(event) => {
                      const phoneNumber = event.target.value;
                      if (
                        /^\d{0,10}$/.test(phoneNumber) ||
                        /^\d{0,13}$/.test(phoneNumber)
                      ) {
                        setWhatsappNumber(phoneNumber);
                        setError((prev) => ({
                          ...prev,
                          ["whatsappNumber"]: ""
                        }));
                      }
                    }}
                    value={whatsappNumber}
                    widthSize="99%"
                    // requiredSymbol
                    InputType="number"
                    // error={Boolean(error?.whatsappNumber)}
                    // helperText={error?.whatsappNumber}
                  />
                </Grid>

                {/* Location, Date of Birth, Education */}
                <Grid item xs={12}>
                  <InputField
                    label="Location"
                    handleChange={(event) => {
                      setLocation(event.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["location"]: ""
                      }));
                    }}
                    value={location}
                    widthSize="99%"
                    requiredSymbol
                    InputType="text"
                    error={Boolean(error?.location)}
                    helperText={error?.location}
                  />
                </Grid>

                <Grid item xs={12}>
                  {/* <SelectInputField
                    label="Select a country"
                    handleSelectChange={handleCountryChange}
                    widthSize="83px"
                    value={selectedCountry}
                    mapValue={allCountries}
                    mapMethod="nationality"
                  /> */}

                  <Autocomplete
                    disablePortal
                    options={allCountries.map((option) => option?.label)}
                    onChange={handleCountryChange}
                    value={selectedCountry}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "46px", 
                        fontSize: "10px"
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                      sx={{
                        "& .MuiInputBase-input": {
                          fontWeight: 400,
                          color: "black",
                          fontSize: "16px", // Adjusts input text size
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "14px",
                        },
                        "& .MuiInputLabel-shrink": {
                          fontSize: "14px", // Adjusts label size when shrunk (on focus or filled)
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px", // Another way to target the label
                          color:"black"
                        },
                      }}
                        {...params}
                        label="Select a country"
                        slotProps={{
                          input: {
                            ...params.InputProps,
                            type: "search"
                          }
                        }}
                      />
                    )}
                  />
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {error?.selectedCountry}
                  </span> */}
                </Grid>
                <Grid item xs={12}>
                  <SelectInputField
                    label="Select a state"
                    handleSelectChange={(e) => {
                      setSelectedState(e.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["selectedState"]: ""
                      }));
                    }}
                    widthSize="83px"
                    value={selectedState}
                    mapValue={allState}
                    mapMethod="State"
                  />
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {error?.selectedState}
                  </span> */}
                </Grid>

                <Grid item xs={12}>
                  <SelectInputField
                    label="Select a city"
                    handleSelectChange={(e) => {
                      setSelectedCity(e.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["selectedCity"]: ""
                      }));
                    }}
                    widthSize="83px"
                    value={selectedCity}
                    mapValue={allCities}
                    mapMethod="permanantCity"
                  />
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {error?.selectedState}
                  </span> */}
                </Grid>


                    {isUserExist?.name !== "admin" ? (

                <Grid item xs={12}>
                  <SelectInputField
                    label="Select a branch"
                    handleSelectChange={(e) => {
                      setSelectedBranch(e.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["selectedCity"]: ""
                      }));
                    }}
                    widthSize="83px"
                    value={selectedBranch}
                    mapValue={userBranchFindRequestSuccess?.data}
                    mapMethod="selectBranch"
                    requiredSymbol
                  />
                  <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {error?.selectedBranch}
                  </span>
                </Grid>
                    ):""}

                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      sx={{ color: "black" }}
                    >
                      Marital Status
                    </FormLabel>

                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={isMarried}
                      onChange={(event) => {
                        setIsMarried(event.target.value);
                        setError((prev) => ({
                          ...prev,
                          ["isMarried"]: ""
                        }));
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <FormControlLabel
                          value="Married"
                          control={<Radio style={{ color: "#f81b82" }} />}
                          label="Married"
                        />
                        <FormControlLabel
                          value="Unmarried"
                          control={<Radio style={{ color: "#f81b82" }} />}
                          label="Unmarried"
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {/* <Grid xs={12} sx={{ ml: 2 }}>
                  <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {error?.isMarried}
                  </span>
                </Grid> */}

                <Grid item xs={6}>
                  <InputField
                    label="Date of Birth"
                    handleChange={(event) => setDateOfBirth(event.target.value)}
                    value={dateOfBirth}
                    widthSize="99%"
                    // requiredSymbol
                    InputType="date"
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    label="Education"
                    handleChange={(event) => setEducation(event.target.value)}
                    value={education}
                    widthSize="99%"
                    // requiredSymbol
                    InputType="text"
                  />
                </Grid>

                {/* Lead Source */}
                <Grid item xs={12}>
                  <SelectInputField
                    label="Lead Source"
                    handleSelectChange={(event) => {
                      setLeadSource(event.target.value);
                      setError((prev) => ({
                        ...prev,
                        leadSource: ""
                      }));
                    }}
                    widthSize="83px"
                    value={leadSource}
                    mapValue={leadSources?.map((item) => item?.leadSource)}
                    mapMethod="leadSource"
                    requiredSymbol
                  />
                  <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px"
                    }}
                  >
                    {error?.leadSource}
                  </span>
                </Grid>

                {leadSourceType?.type === "textBox" && (
                  <Grid item xs={12}>
                    <InputField
                      label={"Lead source name"}
                      handleChange={(event) => {
                        setReferralName(event.target.value);
                        setError((prev) => ({
                          ...prev,
                          leadSourcetype: ""
                        }));
                      }}
                      value={referralName}
                      widthSize="99%"
                      requiredSymbol={
                        leadSourceType?.isMandatory == true ? true : false
                      }
                      InputType="text"
                      error={Boolean(error?.leadSourcetype)}
                      helperText={error?.leadSourcetype}
                    />
                  </Grid>
                )}

                {leadSourceType?.type === "dropdown" && (
                  <Grid item xs={12}>
                    <SelectInputField
                      label="Select Lead Source Name"
                      handleSelectChange={(event) => {
                        setReferralName(event.target.value);
                        setError((prev) => ({
                          ...prev,
                          leadSourcetype: ""
                        }));
                      }}
                      widthSize="83px"
                      value={referralName}
                      mapValue={JSON?.parse(leadSourceType?.items)}
                      mapMethod="leadSource"
                      requiredSymbol={
                        leadSourceType?.isMandatory == true ? true : false
                      }
                    />
                    <span
                      style={{
                        color: "#FF2400",
                        fontSize: "12px"
                      }}
                    >
                      {error?.leadSourcetype}
                    </span>
                  </Grid>
                )}

                {/* {leadSource === "Paid Referral" && (
                  <Grid item xs={12}>
                    <InputField
                      label={
                        leadSource === "Paid Referral" ? "Referred by" : ""
                      }
                      handleChange={(event) =>
                        setReferralName(event.target.value)
                      }
                      value={referralName}
                      widthSize="99%"
                      // requiredSymbol
                      InputType="text"
                    />
                  </Grid>
                )}

                {leadSource === "Unpaid Referral" && (
                  <Grid item xs={12}>
                    <InputField
                      label={
                        leadSource === "Unpaid Referral" ? "Referred by" : ""
                      }
                      handleChange={(event) =>
                        setReferralName(event.target.value)
                      }
                      value={referralName}
                      widthSize="99%"
                      // requiredSymbol
                      InputType="text"
                    />
                  </Grid>
                )}

                {leadSource === "Phone Enquiry" && (
                  <Grid item xs={12}>
                    <InputField
                      label={
                        leadSource === "Phone Enquiry" ? "Referred by" : ""
                      }
                      handleChange={(event) =>
                        setReferralName(event.target.value)
                      }
                      value={referralName}
                      widthSize="99%"
                      // requiredSymbol
                      InputType="text"
                    />
                  </Grid>
                )} */}

                {/* Referral Name */}
                {/* <Grid item xs={12}>
                  <InputField
                    label="Referral Name"
                    handleChange={(event) =>
                      setReferralName(event.target.value)
                    }
                    value={referralName}
                    widthSize="99%"
                    // requiredSymbol
                    InputType="text"
                  />
                </Grid> */}

                {/* Preferred Country */}
                <Grid item xs={12}>
                  <SelectInputField
                    label="Preferred Country"
                    handleSelectChange={(event) => {
                      // const {
                      //   target: { value },
                      // } = event;

                      console.log(event, "eventeventevent");
                      setPreferredCountry(event);
                      setError((prev) => ({
                        ...prev,
                        preferredCountry: ""
                      }));
                      // const filteredArray = event.filter((obj) =>
                      //   obj.hasOwnProperty("countryName")
                      // );

                      // setPreferredCountry((prevRanking) => {
                      //   const updatedRanking = [...prevRanking]; // Create a copy of the current ranking state

                      //   filteredArray.forEach((selectedItem) => {
                      //     const existingIndex = updatedRanking.findIndex(
                      //       (item) => item.id === selectedItem.id
                      //     );

                      //     if (existingIndex !== -1) {
                      //       // Item already exists in the ranking, remove it
                      //       updatedRanking.splice(existingIndex, 1);
                      //     } else {
                      //       // Item doesn't exist in the ranking, add it
                      //       updatedRanking.push({
                      //         name: selectedItem.countryName,
                      //         id: selectedItem.id,
                      //       });
                      //     }
                      //   });
                      //   console.log(updatedRanking,"updatedRankingupdatedRanking")
                      //   return updatedRanking;
                      // });
                    }}
                    widthSize="120px"
                    value={preferredCountry}
                    mapValue={allCountry}
                    mapMethod="allCountry"
                    multiple="true"
                    // requiredSymbol
                  />
                  <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px"
                    }}
                  >
                    {error?.preferredCountry}
                  </span>
                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allCountry}
                    sx={{ width: "83px" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Preferred Country" />
                    )}
                    va
                    onChange={(event) => setLeadSource(event.target.value)}
                  /> */}
                </Grid>

                {/* Remark Description */}
                <Grid item xs={12}>
                  <InputField
                    label="Remarks"
                    handleChange={(event) => setRemark(event.target.value)}
                    value={remark}
                    widthSize="99%"
                    // requiredSymbol
                    InputType="discription"
                    dispcriptionHeight="150px"
                  />
                </Grid>

                {/* Save Button */}
                <Grid item xs={12} textAlign="center">
                  <SubmitButton
                    title="Save"
                    submit="submit"
                    widthSize="90px"
                    heightSize="49px"
                    type="submit"
                    handleSubmit=""
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
